/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

// import GSAP animation effects
import { Power3, TimelineLite } from "gsap";

// import image photos of the products
import bossatrumfos1 from "../../assets/img/potatoes-bags/DSC_0103-3.jpg";
import bossatrumfos2 from "../../assets/img/potatoes-bags/DSC_0117-2.jpg";
import bossatrumfos3 from "../../assets/img/potatoes-bags/DSC_0118-2.jpg";
import bossatrumfos4 from "../../assets/img/potatoes-bags/DSC_0119-2.jpg";
import bossatrumfos6 from "../../assets/img/potatoes-bags/DSC_0121-2.jpg";
import bossatrumfos7 from "../../assets/img/potatoes-bags/DSC_0122-2.jpg";

import caixatrumfos1 from "../../assets/img/potatoes-boxes/DSC_0158-2.jpg";
import caixatrumfos2 from "../../assets/img/potatoes-boxes/DSC_0164-2.jpg";
import caixatrumfos4 from "../../assets/img/potatoes-boxes/DSC_0180-2.jpg";
import caixatrumfos5 from "../../assets/img/potatoes-boxes/DSC_0174-2.jpg";
import caixatrumfos6 from "../../assets/img/potatoes-boxes/DSC_0173-2.jpg";
import caixatrumfos7 from "../../assets/img/potatoes-boxes/DSC_0186-2.jpg";
import caixatrumfos8 from "../../assets/img/potatoes-boxes/DSC_0189-2.jpg";

import sacotrumfos1 from "../../assets/img/potatoes-sacos/DSC_0127-2.jpg";
import sacotrumfos2 from "../../assets/img/potatoes-sacos/DSC_0133-2.jpg";
import sacotrumfos3 from "../../assets/img/potatoes-sacos/DSC_0137-2.jpg";
import sacotrumfos4 from "../../assets/img/potatoes-sacos/DSC_0131-2.jpg";
import sacotrumfos5 from "../../assets/img/potatoes-sacos/DSC_0142-2.jpg";
import sacotrumfos6 from "../../assets/img/potatoes-sacos/DSC_0144-2.jpg";
import sacotrumfos7 from "../../assets/img/potatoes-sacos/DSC_0140-2.jpg";
import sacotrumfos8 from "../../assets/img/potatoes-sacos/DSC_0146-2.jpg";
import sacotrumfos9 from "../../assets/img/potatoes-sacos/DSC_0148-2.jpg";

class PatatasCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.myTweens = new TimelineLite({ paused: true });
    this.products = [];
  }

  componentDidMount() {
    this.myTweens
      .staggerFrom(
        this.products,
        0.8,
        { opacity: 0, y: 40, ease: Power3.easeOut },
        0.2
      )
      .play();
  }

  render() {
    return (
      <div className="section text-center">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto">
              <h2 className="title">Descubre nuestros productos estrella</h2>
              <h5 className="ml-auto mr-auto">
                Aquí podrás encontrar los distintos productos de nuestra marca
                que destaca desde hace años por su calidad y presentación
              </h5>
            </Col>
          </Row>
          <blockquote className="blockquote">
            <h5 className="mb-0">Bolsas</h5>
          </blockquote>
          <Row>
            <div
              ref={(div) => (this.products[0] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col md={10}>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossatrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 3 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Bolsa de papel que contiene patata de carne blanca apta
                      para hervir y freír
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[1] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col md={5}>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossatrumfos2} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 3 kg</CardTitle>
                    <CardText>
                      Nuestra bolsa de papel insignia de color amarillo que
                      define nuestra empresa. Podrás encontrar patata de carne
                      blanca según la temporada, como Kennebec, Divaa, etc. Apta
                      para todo uso
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[2] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col md={5}>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossatrumfos3} alt="producto1" />
                  <CardBody>
                    <CardTitle>Patates del Bufet 2 kg</CardTitle>
                    <CardText>
                      En esta bolsa, que lleva la foto de Oliana, podrás
                      encontrar la variedad de patata más exclusiva. Es una
                      patata ancestral que cultivaban nuestros abuelos y que
                      ahún cultivamos. Muy apreciada por su sabor. Sirve para
                      todo uso culinario
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <Row className="ml-auto mr-auto">
            <div
              ref={(div) => (this.products[3] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossatrumfos4} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Bolsa de papel de donde prodrás encontrar variedades de
                      carne blanca. Mr. Trumfos es nuestra segunda marca
                      comercial
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[6] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossatrumfos7} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu de 3 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Bolsa de papel donde podrás encontrar patata roja de carne
                      blanca. Disponible en variedades como Redpontiac o Rudolf
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[5] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossatrumfos6} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 2 kg</CardTitle>
                    <CardText>
                      Rediseño de la bolsa de papel donde podrás encontrar
                      patata roja de carne blanca. Disponible en variedades como
                      Redpontiac o Rudolf
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <br />
          <blockquote className="blockquote">
            <h5 className="mb-0">Cajas</h5>
          </blockquote>
          <Row className="ml-auto mr-auto">
            <div
              ref={(div) => (this.products[7] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={caixatrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trumfos Guarnició</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Caja de patata "baby". Nuestras patatas de calibre pequeño
                      ideales para cocinar enteras
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[8] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={caixatrumfos2} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trumfos Freír 10 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Caja de patata especial para freír. Podrás encontrar
                      variedades como Monalisa, Ágata, etc.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <Row className="ml-auto mr-auto">
            <div
              ref={(div) => (this.products[10] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={caixatrumfos4} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trumfos 10 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Caja donde podrás encontrar patatas de carne y piel blanca
                      a granel. Indicado para tiendas
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[11] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={caixatrumfos5} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trumfos 10 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Otro formato de caja donde podrás encontrar patatas de
                      carne y piel blanca a granel
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[12] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={caixatrumfos6} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trumfos 10 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Caja donde podrás encontrar patatas de carne blanca y piel
                      roja a granel
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <Row className="ml-auto mr-auto">
            <div
              ref={(div) => (this.products[13] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={caixatrumfos7} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 12 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Caja donde podrás encontrar patatas de carne y piel blanca
                      a granel
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[14] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={caixatrumfos8} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 12 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Caja donde podrás encontrar patatas de carne blanca y piel
                      roja a granel
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <br />
          <blockquote className="blockquote">
            <h5 className="mb-0">Sacos</h5>
          </blockquote>
          <Row className="ml-auto mr-auto">
            <div
              ref={(div) => (this.products[16] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 10 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}
                    <CardText>
                      Saco de papel donde encontrarás variedades de piel blanca
                      como Kennebec, Divaa, Sifra..., según temporada
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[17] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos2} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 15 kg</CardTitle>
                    <CardText>
                      Saco de papel donde encontrarás variedades de piel blanca
                      como Kennebec, Divaa, Sifra..., según temporada
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[18] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos3} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 15 kg</CardTitle>
                    <CardText>
                      Saco de papel donde encontrarás variedades de piel roja
                      como Repontiac o Rudolf
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <Row className="ml-auto mr-auto">
            <div
              ref={(div) => (this.products[19] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos4} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trumfos 20 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Saco donde podrás encontrar patata especial para freír
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[20] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos5} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trumfos 20 kg</CardTitle>
                    <CardText>
                      Saco donde podrás encontrar patata para todo uso
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[21] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos6} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trumfos 20 kg</CardTitle>
                    <CardText>
                      Saco donde podrás encontrar patata especial para hervir
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <Row className="ml-auto mr-auto">
            <div
              ref={(div) => (this.products[22] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos7} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 15 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Saco de papel de gran calidad donde podrás encontrar piel
                      blanca
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[23] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos8} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 15 kg</CardTitle>
                    <CardText>
                      Saco de papel edición especial de alta montaña donde
                      podrás encontrar variedades de carne blanca
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[24] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={sacotrumfos9} alt="producto1" />
                  <CardBody>
                    <CardTitle>Trumfos del Pirineu 25 kg</CardTitle>
                    <CardText>
                      Saco de rafia donde podrás encontrar variedades de carne
                      blanca
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default PatatasCatalog;
