/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import reactstrap components
// import { Container, Row, Col } from "reactstrap";

const SectionHistoryTimeline = () => {
  return (
    <section id="timeline">
      <div className="tl-item">
        <div
          className="tl-bg"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/patata-campo-4.jpg") + ")",
          }}
        />
        <div className="tl-year">
          <p className="f2 heading--sansSerif">1999</p>
        </div>
        <div className="tl-content">
          <h1>Año de creación</h1>
          <br />
          <p>Elaborats Prepirineu S.L. se funda</p>
        </div>
      </div>
      <div className="tl-item">
        <div
          className="tl-bg"
          style={{
            backgroundImage:
              "url(" +
              require("../../assets/img/old/bossa-patates-velles-3.jpg") +
              ")",
          }}
        />
        <div className="tl-year">
          <p className="f2 heading--sanSerif">2000</p>
        </div>
        <div className="tl-content">
          <h1>Trumfos del Pirineu</h1>
          <br />
          <p>Creamos la conocida marca Trumfos del Pirineu</p>
        </div>
      </div>
      <div className="tl-item">
        <div
          className="tl-bg"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/old/mercabarna-3.jpg") + ")",
          }}
        />
        <div className="tl-year">
          <p className="f2 heading--sanSerif">2012</p>
        </div>
        <div className="tl-content">
          <h1>Mercabarna</h1>
          <br />
          <p>Un paso más</p>
        </div>
      </div>
      <div className="tl-item">
        <div
          className="tl-bg"
          style={{
            backgroundImage:
              "url(" + require("../../assets/img/old/actualidad-1.jpg") + ")",
          }}
        />
        <div className="tl-year">
          <p className="f2 heading--sanSerif">Actualidad</p>
        </div>
        <div className="tl-content">
          <h1>¡Del campo a tu casa!</h1>
          <br />
          <p>Continuamos trabajando para hacerte llegar el mejor producto</p>
        </div>
      </div>
    </section>
  );
};

export default SectionHistoryTimeline;
