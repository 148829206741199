/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React, { Component } from "react";

// import reactstrap components
import { Container, Row, Col, FormGroup, Label, Button } from "reactstrap";

// connect to Firebase Database
import db from "./FirebaseConfig.js";

class SectionContactForm extends Component {
  // initalize state
  constructor(props) {
    super(props);
    this.state = {
      form: [],
      alert: false,
      alertData: {},
      submitIsChecked: false,
    };
  }

  // Show up alert when it is sent by user
  showAlert(type, message) {
    this.setState({
      alert: true,
      alertData: { type, message },
    });
    setTimeout(() => {
      this.setState({ alert: false });
    }, 4000);
  }

  // Delete all the elements on the contact form
  resetForm() {
    this.refs.contactForm.reset();
  }

  onClickSubmit() {
    this.setState({ submitIsChecked: !this.state.submitIsChecked });
  }

  // Send information to Firebase Database
  sendMessage(e) {
    // Stops default element action
    e.preventDefault();

    // Create an object with the obtained values of the inputs
    const params = {
      name: this.inputName.value,
      surname: this.inputSurname.value,
      email: this.inputEmail.value,
      phone: this.inputPhone.value,
      message: this.textAreaMessage.value,
      time: new Date().toLocaleString().replace(",", "").replace(/:.. /, " "),
    };

    // Validate that Agreement checkbox is checked that the main elments of our form are not empty
    if (!this.state.submitIsChecked) {
      this.showAlert(
        "warning",
        "Para enviar se necesita aceptar el Aviso Legal. Gracias."
      );
    } else if (
      params.name &&
      params.surname &&
      params.email &&
      params.phone &&
      params.message
    ) {
      // send our object "params" to firebase databse
      db.collection("webpage-contactform")
        .add(params)
        .then(() => {
          // If everthing ok, refresh state to show up an alert
          this.showAlert(
            "success",
            "El mensaje se ha enviado con éxito. ¡Muchas gracias por contactar con nosotros!"
          );
        })
        .catch(() => {
          // If error happens, refresh state to show up the error
          this.showAlert(
            "danger",
            "El mesaje no se ha podido enviar debido a un error. Vuélvelo a intentar, por favor."
          );
        });
      // clean up form calling resetForm function
      this.resetForm();
    } else {
      // In case of not filling the elements is necessary to show up a message alert
      this.showAlert(
        "warning",
        "Por favor, rellena todo el formulario. Gracias."
      );
    }
  }

  render() {
    return (
      <div className="section section-white text-center">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="15">
              <h2 className="title">¿Necesitas más información?</h2>
              <h3 className="title">¡No dudes en contactar con nosotros!</h3>
              <h5 className="description">
                Nos pondremos en contacto contigo lo antes posible
              </h5>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <form onSubmit={this.sendMessage.bind(this)} ref="contactForm">
                <Label>
                  <h5 className="description" style={{ textAlign: "start" }}>
                    Nombre y Apellidos
                  </h5>
                </Label>
                <Row>
                  <Col>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Nombre"
                      id="name"
                      ref={(name) => (this.inputName = name)}
                    />
                  </Col>
                  <Col>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Apellidos"
                      id="surname"
                      ref={(surname) => (this.inputSurname = surname)}
                    />
                  </Col>
                </Row>
                <br />
                <FormGroup>
                  <Label>
                    <h5 className="description" style={{ textAlign: "start" }}>
                      Correo Electrónico
                    </h5>
                  </Label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Ej: example@mail.com"
                    id="email"
                    ref={(email) => (this.inputEmail = email)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <h5 className="description" style={{ textAlign: "start" }}>
                      Teléfono de Contacto
                    </h5>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ej: +34 999 000 000"
                    id="phone"
                    ref={(phone) => (this.inputPhone = phone)}
                  />
                </FormGroup>
                <br />
                <FormGroup>
                  <Label>
                    <h5 className="description" style={{ textAlign: "start" }}>
                      Mensaje
                    </h5>
                  </Label>
                  <textarea
                    type="textarea"
                    rows="4"
                    className="form-control"
                    id="message"
                    ref={(message) => (this.textAreaMessage = message)}
                  />
                </FormGroup>
                <br />
                <FormGroup>
                  <input
                    type="checkbox"
                    id="checkinput"
                    checked={this.state.submitChecked}
                    onChange={this.onClickSubmit.bind(this)}
                  />
                  <Label>
                    He leído y consiento los tratamientos previstos en el
                    <a href="/aviso-legal"> Aviso Legal</a>.
                  </Label>
                </FormGroup>
                <br />
                <Button color="primary" type="submit">
                  Enviar
                </Button>
              </form>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {this.state.alert && (
                <div
                  className={`alert alert-${this.state.alertData.type}`}
                  role="alert"
                >
                  <div className="container text-center">
                    {this.state.alertData.message}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SectionContactForm;
