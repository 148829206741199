/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/
import React from "react";

// core components
import LandingPageNavbar from "../components/Navbars/LandingPageNavbar.js";
import NoticiasHeader from "../components/Headers/NoticiasHeader.js";
import EmpresaFooter from "../components/Footers/EmpresaFooter.js";

// noticias page sections
import SectionNoticias from "../pages/noticias-sections/SectionNoticias.js";

const NoticiasPage = () => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <LandingPageNavbar />
      <NoticiasHeader />
      <SectionNoticias />
      <EmpresaFooter />
    </>
  );
};

export default NoticiasPage;
