/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import GSAP + ScrollMagic for animations
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { TweenLite, Power3 } from "gsap";
// import ScrollMagic from "scrollmagic";

// react responsive
import MediaQuery from "react-responsive";

// import reactstrap components
import { Container, Row, Col } from "reactstrap";

// import images
import patatasection1 from "../../assets/img/old/socis-antic.jpg";
import patatasection2 from "../../assets/img/old/mercabarna-4.jpg";

class SectionHistoryText extends React.Component {
  constructor(props) {
    super(props);
    this.tweenContent0 = null;
    this.tweenContent1 = null;
    this.tweenContent2 = null;
    this.tweenImage1 = null;
    this.tweenImage2 = null;
    this.contentHistory0 = null;
    this.contentHistory1 = null;
    this.contentHistory2 = null;
    this.imageHistory1 = null;
    this.imageHistory2 = null;
  }

  componentDidMount() {
    this.tweenContent0 = TweenLite.from(this.contentHistory0, 1, {
      opacity: 0,
      y: -50,
      ease: Power3.easeIn,
    });

    this.tweenContent1 = TweenLite.from(this.contentHistory1, 1, {
      opacity: 0,
      y: -50,
      ease: Power3.easeIn,
    });

    this.tweenImage1 = TweenLite.from(this.imageHistory1, 1, {
      opacity: 0,
      x: 50,
      ease: Power3.easeIn,
    });

    this.tweenContent2 = TweenLite.from(this.contentHistory2, 1, {
      opacity: 0,
      y: 50,
      ease: Power3.easeIn,
    });

    this.tweenImage2 = TweenLite.from(this.imageHistory2, 1, {
      opacity: 0,
      x: -50,
      ease: Power3.easeIn,
    });

    // // create a scene
    // new ScrollMagic.Scene({
    //   triggerElement: "#history-text",
    //   duration: 500, // the scene should last for a scroll distance of 100px
    //   offset: 200 // start this scene after scrolling for 50px
    // })
    //   .setTween("#description-history", 1, {
    //     opacity: 1,
    //     y: 50,
    //     ease: Power3.easeOut
    //   })
    //   // .setPin("#producto1-lp") // pins the element for the the scene's duration
    //   .addTo(this.controller) // assign the scene to the controller
    //   .addIndicators();

    // // create a scene
    // new ScrollMagic.Scene({
    //   triggerElement: "#history-text",
    //   duration: 500, // the scene should last for a scroll distance of 100px
    //   offset: 200 // start this scene after scrolling for 50px
    // })
    //   .setTween("#photo-empresa1", 1, {
    //     opacity: 1,
    //     x: -50,
    //     ease: Power3.easeOut
    //   })
    //   // .setPin("#producto1-lp") // pins the element for the the scene's duration
    //   .addTo(this.controller) // assign the scene to the controller
    //   .addIndicators();
  }

  render() {
    return (
      <>
        <MediaQuery maxDeviceWidth={1024}>
          <div className="section section-dark " id="history-text">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto">
                  <h2 className="title">¿Quiénes somos?</h2>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                  <div ref={(div) => (this.contentHistory0 = div)}>
                    <h2 className="description" style={{ fontSize: "2.5rem" }}>
                      <i className="nc-icon nc-sun-fog-29 mr-3" />
                      Nuestra Historia
                    </h2>
                  </div>
                  <br />
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto">
                  <div ref={(div) => (this.contentHistory1 = div)}>
                    <h5
                      className="description"
                      id="description-history"
                      style={{
                        textAlign: "justify",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      Trumfos del Pirineu surgió en Oliana, un pequeño pueblo
                      del Alt Urgell (Lérida). Lugar de tradición de patata de
                      mucha calidad gracias a un clima y una latitud idóneos.
                    </h5>
                    <br />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                  <div ref={(div) => (this.contentHistory2 = div)}>
                    <h5
                      className="description"
                      id="description-history"
                      style={{
                        textAlign: "left",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      En 2012 dimos un paso más y abrimos un nuevo canal de
                      venta a Mercabarna. Se trata del mercado central de
                      alimentación de Barcelona, el más importante en frutas y
                      hortalizas del sur de Europa.
                    </h5>
                    <br />
                    <h5
                      className="description"
                      id="description-history"
                      style={{
                        textAlign: "left",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      Nuestra pasión para llevar al mercado la tradición y
                      conocimiento del sector agrícola, nos ha permitido
                      posicionarnos como una empresa referente en términos de
                      calidad.
                    </h5>
                  </div>
                  <br />
                </Col>
                <Col className="ml-auto mr-auto" md="7">
                  <div ref={(div) => (this.imageHistory2 = div)}>
                    <img
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "80%",
                        transform: "translateY(10%)",
                        // eslint-disable-next-line
                        transform: "translateX(5%)",
                      }}
                      src={patatasection2}
                      alt="patata-left"
                      className="image-history-left photo"
                      id="photo-empresa2"
                    />
                  </div>
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <Row>
                <Col className="ml-auto mr-auto">
                  <h2 className="title">Nuestra historia en fechas</h2>
                </Col>
              </Row>
            </Container>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1025}>
          <div className="section section-dark " id="history-text">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto">
                  <h2 className="title">¿Quiénes somos?</h2>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                  <div ref={(div) => (this.contentHistory0 = div)}>
                    <h2 className="description" style={{ fontSize: "2.5rem" }}>
                      <i className="nc-icon nc-sun-fog-29 mr-3" />
                      Nuestra Historia
                    </h2>
                  </div>
                  <br />
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto">
                  <div ref={(div) => (this.contentHistory1 = div)}>
                    <h5
                      className="description"
                      id="description-history"
                      style={{ textAlign: "left" }}
                    >
                      Trumfos del Pirineu surgió en Oliana, un pequeño pueblo
                      del Alt Urgell (Lérida). Lugar de tradición de patata de
                      mucha calidad gracias a un clima y una latitud idóneos.
                    </h5>
                    <br />
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col className="ml-auto mr-auto" md="7">
                  <div ref={(div) => (this.imageHistory2 = div)}>
                    <img
                      src={patatasection2}
                      alt="patata-left"
                      className="image-history-left photo"
                      id="photo-empresa2"
                    />
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <div ref={(div) => (this.contentHistory2 = div)}>
                    <h5
                      className="description"
                      id="description-history"
                      style={{ textAlign: "left" }}
                    >
                      En 2012 dimos un paso más y abrimos un nuevo canal de
                      venta a Mercabarna. Se trata del mercado central de
                      alimentación de Barcelona, el más importante en frutas y
                      hortalizas del sur de Europa.
                    </h5>
                    <br />
                    <h5
                      className="description"
                      id="description-history"
                      style={{ textAlign: "left" }}
                    >
                      Nuestra pasión para llevar al mercado la tradición y
                      conocimiento del sector agrícola, nos ha permitido
                      posicionarnos como una empresa referente en términos de
                      calidad.
                    </h5>
                    <br />
                  </div>
                  <br />
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <Row>
                <Col className="ml-auto mr-auto">
                  <h2 className="title">Nuestra historia en fechas</h2>
                </Col>
              </Row>
            </Container>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default SectionHistoryText;
