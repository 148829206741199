/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText
} from "reactstrap";

// import GSAP animation effects
import { Power3, TimelineLite } from "gsap";

// import image photos of the products
import bossamrtrumfos1 from "../../assets/img/mr-trufmos-bossa1.jpg";

class ExoticosCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.myTweens = new TimelineLite({ paused: true });
    this.products = [];
  }

  componentDidMount() {
    this.myTweens
      .staggerFrom(
        this.products,
        0.8,
        { opacity: 0, y: 40, ease: Power3.easeOut },
        0.2
      )
      .play();
  }

  render() {
    return (
      <div className="section text-center">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto">
              <h2 className="title">Descubre nuestros productos estrella</h2>
              <h5 className="ml-auto mr-auto">
                En Trumfos del Pirineu podrás encontrar nuestras marcas estrella
                que destacan desde hace haños por su calidad y presentación
              </h5>
            </Col>
          </Row>
          <blockquote className="blockquote">
            <h5 className="mb-0">Bolsas</h5>
          </blockquote>
          <Row>
            <div
              ref={div => (this.products[0] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col md={10}>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[1] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col md={5}>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[2] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col md={5}>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <Row className="ml-auto mr-auto">
            <div
              ref={div => (this.products[3] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[4] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[5] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <Row className="ml-auto mr-auto">
            <div
              ref={div => (this.products[6] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[7] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[8] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <br />
          <blockquote className="blockquote">
            <h5 className="mb-0">Sacos</h5>
          </blockquote>
          <Row className="ml-auto mr-auto">
            <div
              ref={div => (this.products[9] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[10] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[11] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
          <Row className="ml-auto mr-auto">
            <div
              ref={div => (this.products[12] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[13] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={div => (this.products[14] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={bossamrtrumfos1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Mr. Trufmos 3 kg</CardTitle>
                    <CardText>
                      Bolsa de papel de 3 kg donde prodrás encontrar variedades
                      como la Kennebec, Agata, etc
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ExoticosCatalog;
