/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/
import React from "react";

// core components
import LandingPageNavbar from "../components/Navbars/LandingPageNavbar.js";
import ExoticosHeader from "../components/Headers/ExoticosHeader.js";
import LandingPageFooter from "../components/Footers/LandingPageFooter.js";

// exoticos page sections
import ExoticosCatalog from "./productos-sections/ExoticosCatalog.js";

const ExoticosPage = () => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <LandingPageNavbar />
      <ExoticosHeader />
      <ExoticosCatalog />
      <LandingPageFooter />
    </>
  );
};

export default ExoticosPage;
