/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// react responsive
import MediaQuery from "react-responsive";

// import GSAP + ScrollMagic for animations
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { TweenLite, Power3 } from "gsap";
// import ScrollMagic from "scrollmagic";

// import reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// import images
// import cebollasection from "../../assets/img/patata-campo-2.jpg";
import cebolla1 from "../../assets/img/onions1.jpg";

class SectionCebollasAjos extends React.Component {
  constructor(props) {
    super(props);
    this.tweenContent = null;
    this.tweenButton = null;
    this.cebollaSection = null;
    this.discoverButton = null;
  }

  componentDidMount() {
    this.tweenContent = TweenLite.from(this.cebollaSection, 1, {
      opacity: 0,
      x: 100,
      ease: Power3.easeIn,
      delay: 1,
    });

    this.tweenButton = TweenLite.from(this.discoverButton, 1, {
      opacity: 0,
      x: -100,
      ease: Power3.easeIn,
      delay: 1,
    });

    // // create a scene
    // new ScrollMagic.Scene({
    //   triggerElement: "#cebollas-section",
    //   duration: 150, // the scene should last for a scroll distance of 100px
    //   offset: -20 // start this scene after scrolling for 50px
    // })
    //   .setTween("#cebollas-area", 1, {
    //     opacity: 1,
    //     x: -100,
    //     ease: Power3.easeOut
    //   })
    //   // .setPin("#producto1-lp") // pins the element for the the scene's duration
    //   .addTo(this.controller) // assign the scene to the controller
    //   .addIndicators();

    // // create a scene
    // new ScrollMagic.Scene({
    //   triggerElement: "#cebollas-section",
    //   duration: 150, // the scene should last for a scroll distance of 100px
    //   offset: -20 // start this scene after scrolling for 50px
    // })
    //   .setTween("#cebollas-button", 1, {
    //     opacity: 1,
    //     x: 100,
    //     ease: Power3.easeOut
    //   })
    //   // .setPin("#producto1-lp") // pins the element for the the scene's duration
    //   .addTo(this.controller) // assign the scene to the controller
    //   .addIndicators();
  }
  render() {
    return (
      <>
        <Container fluid={true} className="section-dark" id="cebollas-section">
          <MediaQuery maxWidth={1570}>
            <Row className="d-flex justify-content-center">
              <Col sm={{ size: "auto", offset: 0 }}>
                <div ref={(div) => (this.cebollaSection = div)}>
                  <img
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "70%",
                      transform: "translateY(20%)",
                    }}
                    id="cebollas-area"
                    src={cebolla1}
                    alt="cebolla-left"
                    className="image-productos-patata align-middle"
                  />
                </div>
              </Col>
              <Col
                sm={{ size: "auto", offset: 0 }}
                style={{ paddingTop: "2rem" }}
              >
                <div ref={(div) => (this.discoverButton = div)}>
                  <Row>
                    <h1
                      id="cebollas-area"
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "70%",
                        textAlign: "center",
                      }}
                    >
                      Cebollas
                    </h1>
                  </Row>
                  <br />
                  <Row>
                    <h4
                      id="cebollas-area"
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "70%",
                        textAlign: "center",
                      }}
                    >
                      Las mejores variedades de cebollas a tu alcance.
                    </h4>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Button
                      // id="cebollas-button"
                      className="btn-round btn-gold"
                      color="info"
                      onClick={(event) =>
                        (window.location.href = "/productos/cebollas-y-ajos")
                      }
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      DESCUBRE MÁS
                    </Button>
                  </Row>
                  <br />
                  <br />
                </div>
              </Col>
            </Row>
          </MediaQuery>
          <MediaQuery minWidth={1570}>
            <Row>
              <Col
                sm={{ size: "auto" }}
                style={{ paddingTop: "2rem", paddingLeft: "13rem" }}
              >
                <div ref={(div) => (this.discoverButton = div)}>
                  <Row style={{ float: "right" }}>
                    <h1 id="cebollas-area">Cebollas</h1>
                  </Row>
                  <br />
                  <Row style={{ float: "right", paddingTop: "2rem" }}>
                    <h4 id="cebollas-area">
                      Las mejores variedades de cebollas a tu alcance.
                    </h4>
                  </Row>
                  <br />
                  <br />
                  <Row style={{ float: "right", paddingTop: "4rem" }}>
                    <Button
                      // id="cebollas-button"
                      className="btn-round btn-gold"
                      color="info"
                      onClick={(event) =>
                        (window.location.href = "/productos/cebollas-y-ajos")
                      }
                    >
                      DESCUBRE MÁS
                    </Button>
                  </Row>
                </div>
              </Col>
              <Col sm={{ size: "auto", offset: 1 }}>
                <div ref={(div) => (this.cebollaSection = div)}>
                  <img
                    id="cebollas-area"
                    src={cebolla1}
                    alt="cebolla-left"
                    className="image-productos-patata"
                  />
                </div>
              </Col>
            </Row>
          </MediaQuery>
        </Container>
      </>
    );
  }
}

export default SectionCebollasAjos;
