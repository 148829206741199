/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

const PoliticaCookiesHeader = () => {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <div
      style={{
        backgroundImage:
          "url(" + require("../../assets/img/header-camps-1.jpg") + ")"
      }}
      className="page-header page-header-xs"
      data-parallax={true}
      ref={pageHeader}
    >
      <div className="filter" />
      <h1 className="motto">Política Cookies</h1>
    </div>
  );
};

export default PoliticaCookiesHeader;
