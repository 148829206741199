/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/scss/elaborats-prepirineu.scss";
import "./demo/demo.css";

// pages
import LandingPage from "./pages/LandingPage.js";
import ProductosPage from "./pages/ProductosPage.js";
import PatatasPage from "./pages/PatatasPage.js";
import CebollasAjosPage from "./pages/CebollasAjosPage.js";
import ExoticosPage from "./pages/ExoticosPage.js";
import EmpresaPage from "./pages/EmpresaPage.js";
import NoticiasPage from "./pages/NoticiasPage.js";
import ContactoPage from "./pages/ContactoPage.js";
import AvisoLegalPage from "./pages/AvisoLegalPage.js";
import PoliticaCookiesPage from "./pages/PoliticaCookiesPage.js";
import PoliticaRedesSocialesPage from "./pages/PoliticaRedesSocialesPage.js";

// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/productos/patatas"
        render={(props) => <PatatasPage {...props} />}
      />
      <Route
        path="/productos/cebollas-y-ajos"
        render={(props) => <CebollasAjosPage {...props} />}
      />
      <Route
        path="/productos/exoticos"
        render={(props) => <ExoticosPage {...props} />}
      />
      <Route
        path="/productos"
        render={(props) => <ProductosPage {...props} />}
      />
      <Route path="/empresa" render={(props) => <EmpresaPage {...props} />} />
      <Route path="/noticias" render={(props) => <NoticiasPage {...props} />} />
      <Route path="/contacto" render={(props) => <ContactoPage {...props} />} />
      <Route
        path="/aviso-legal"
        render={(props) => <AvisoLegalPage {...props} />}
      />
      <Route
        path="/politica-cookies"
        render={(props) => <PoliticaCookiesPage {...props} />}
      />
      <Route
        path="/politica-rrss"
        render={(props) => <PoliticaRedesSocialesPage {...props} />}
      />
      <Redirect to="/landing-page" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
