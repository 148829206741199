/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// react responsive
import MediaQuery from "react-responsive";

// import GSAP + ScrollMagic for animations
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { TweenLite, Power3 } from "gsap";
// import ScrollMagic from "scrollmagic";

// import reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// import images
// import patatasection from "../../assets/img/patata-campo-2.jpg";
import potatoe1 from "../../assets/img/potatoes1.jpg";

class SectionPatatas extends React.Component {
  constructor(props) {
    super(props);
    this.tweenContent = null;
    this.tweenButton = null;
    this.patataSection = null;
    this.discoverButton = null;
  }

  componentDidMount() {
    this.tweenContent = TweenLite.from(this.patataSection, 1, {
      opacity: 0,
      x: -100,
      ease: Power3.easeIn,
    });

    this.tweenButton = TweenLite.from(this.discoverButton, 1, {
      opacity: 0,
      x: 100,
      ease: Power3.easeIn,
    });

    // // create a scene
    // new ScrollMagic.Scene({
    //   triggerElement: "#patatas-section",
    //   duration: 150, // the scene should last for a scroll distance of 100px
    //   offset: 150 // start this scene after scrolling for 50px
    // })
    //   .setTween("#patatas-area", 1, {
    //     opacity: 1,
    //     x: 100,
    //     ease: Power3.easeOut
    //   })
    //   // .setPin("#producto1-lp") // pins the element for the the scene's duration
    //   .addTo(this.controller) // assign the scene to the controller
    //   .addIndicators();

    // // create a scene
    // new ScrollMagic.Scene({
    //   triggerElement: "#patatas-section",
    //   duration: 150, // the scene should last for a scroll distance of 100px
    //   offset: 150 // start this scene after scrolling for 50px
    // })
    //   .setTween("#patatas-button", 1, {
    //     opacity: 1,
    //     x: -100,
    //     ease: Power3.easeOut
    //   })
    //   // .setPin("#producto1-lp") // pins the element for the the scene's duration
    //   .addTo(this.controller) // assign the scene to the controller
    //   .addIndicators();
  }

  render() {
    return (
      <>
        <Container fluid={true} className="section-dark" id="patatas-section">
          <MediaQuery maxWidth={1570}>
            <Row className="d-flex justify-content-center">
              <Col sm={{ size: "auto", offset: 0 }}>
                <div ref={(div) => (this.patataSection = div)}>
                  <img
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "70%",
                      transform: "translateY(20%)",
                    }}
                    src={potatoe1}
                    alt="patata-left"
                    className="image-productos-patata"
                  />
                </div>
              </Col>
              <Col
                sm={{ size: "auto", offset: 0 }}
                style={{ paddingTop: "2rem" }}
              >
                <div ref={(div) => (this.discoverButton = div)}>
                  <Row>
                    <h1
                      id="patatas-area"
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "70%",
                        textAlign: "center",
                      }}
                    >
                      Patatas
                    </h1>
                  </Row>
                  <br />
                  <Row>
                    <h4
                      id="patatas-area"
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "70%",
                        textAlign: "center",
                      }}
                    >
                      Las mejores variedades de patatas a tu alcance.
                    </h4>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <Row>
                    <Button
                      // id="patatas-button"
                      className="btn-round btn-gold"
                      color="info"
                      onClick={(event) =>
                        (window.location.href = "/productos/patatas")
                      }
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      DESCUBRE MÁS
                    </Button>
                  </Row>
                  <br />
                  <br />
                </div>
              </Col>
            </Row>
          </MediaQuery>
          <MediaQuery minWidth={1570}>
            <Row>
              <Col sm={{ size: "auto", offset: 2 }}>
                <div ref={(div) => (this.patataSection = div)}>
                  <img
                    src={potatoe1}
                    alt="patata-left"
                    className="image-productos-patata"
                  />
                </div>
              </Col>
              <Col
                sm={{ size: "auto", offset: 0 }}
                style={{ paddingTop: "2rem", paddingLeft: "9rem" }}
              >
                <div ref={(div) => (this.discoverButton = div)}>
                  <Row>
                    <h1 id="patatas-area">Patatas</h1>
                  </Row>
                  <br />
                  <Row>
                    <h4 id="patatas-area">
                      Las mejores variedades de patatas a tu alcance.
                    </h4>
                  </Row>
                  <br />
                  <Row style={{ paddingTop: "50px" }}>
                    <Button
                      // id="patatas-button"
                      className="btn-round btn-gold"
                      color="info"
                      onClick={(event) =>
                        (window.location.href = "/productos/patatas")
                      }
                    >
                      DESCUBRE MÁS
                    </Button>
                  </Row>
                </div>
              </Col>
            </Row>
          </MediaQuery>
        </Container>
      </>
    );
  }
}

export default SectionPatatas;

/* <Row className="text-center">
<Col>
    <div ref={div => (this.patataSection = div)}>
      <img
        id="patatas-area"
        src={patatasection}
        alt="patata-left"
        className="image-productos-left photo"
      />
    </div>
  </Col>
  <Col>
    <div ref={div => (this.discoverButton = div)}>
      <Row>
        <h1 className="image-productos-right h1" id="patatas-area">
          Patatas
        </h1>
      </Row>
      <Row>
        <h4 className="image-productos-right h4" id="patatas-area">
          Las mejores variedades de patatas a tu alcance.
        </h4>
      </Row>
      <Row>
        <Button
          // id="patatas-button"
          className="btn-round btn-gold btn-productos-centered"
          color="info"
          onClick={event => (window.location.href = "/empresa")}
        >
          DESCUBRE MÁS
        </Button>
      </Row>
    </div>
  </Col>
</Row> */

// <>
//   <div className="section section-white">
//     <Container>
//       <Row>
//         <Col>
//           <img
//             src={patatasection}
//             alt="patata-left"
//             align="left"
//             className="image-productos-left photo"
//           />
//         </Col>
//         <Col>
//           <h1 className="image-productos-left h1">Patatas</h1>
//           <h4 className="image-productos-left h4">
//             Anim non qui dolore sint est sit reprehenderit esse Lorem nulla.
//             Culpa do velit ex irure reprehenderit mollit ad esse mollit. Et
//             occaecat exercitation quis cillum consectetur in nisi magna
//             nostrud id id sunt elit. Aute labore velit nisi elit cupidatat
//             nisi id sint ea do.
//           </h4>
//         </Col>
//       </Row>

//       {/* <Media>
//         <Media left href="#">
//           <Media>
//             object
//             data-src="../../assets/img/0099-min.JPG"
//             alt="Patatas media"
//           />
//         </Media>
//         <Media body>
//           <Media heading>Patatas</Media>
//           Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
//           scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum
//           in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac
//           nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
//         </Media>
//       </Media> */}
//       <br />
//     </Container>
//   </div>
// </>
