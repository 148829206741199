/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import GSAP animation effects
import { TweenLite, Power3 } from "gsap";

// import reactstrap components
import { Container, Row, Col } from "reactstrap";

class SectionTerminosCondiciones extends React.Component {
  constructor(props) {
    super(props);
    this.tweenContent = null;
    this.content = null;
  }

  componentDidMount() {
    this.tweenContent = TweenLite.from(this.content, 1, {
      opacity: 0,
      x: -100,
      ease: Power3.easeIn,
    });
  }

  render() {
    return (
      <Container>
        <div
          className="section section-white text-jsutify"
          ref={(div) => (this.content = div)}
        >
          <Row>
            <h2 className="title ml-auto mr-auto">Términos y Condiciones</h2>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto">
              <h4 style={{ textAlign: "justify" }}> Condiciones de Uso</h4>
              <br />
              <p style={{ textAlign: "justify" }}>
                En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios
                de la Sociedad de la Información y de Comercio Electrónico
                (LSSI-CE), ELABORATS PREPIRINEU SL informa que es titular del
                sitio web{" "}
                <a href="www.trumfosdelpirineu.com">
                  www.trumfosdelpirineu.com
                </a>
                . De acuerdo con la exigencia del artículo 10 de la citada Ley,
                ELABORATS PREPIRINEU SL informa de los siguientes datos:
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                El titular de este sitio web es ELABORATS PREPIRINEU SL, con CIF
                B25409970 y domicilio social en PABELLÓN E No 5017, 5018, 5019
                08040, MERCABARNA (BARCELONA), inscrita en el Registro Mercantil
                de Lleida, en el tomo 557, folio 33, hoja L-10427. La dirección
                de correo electrónico de contacto con la empresa es:
                oliana@trumfosdelpirineu.com
              </p>
              <br />
              <h6 style={{ textAlign: "justify" }}>
                Usuario y régimen de responsabilidades
              </h6>
              <p style={{ textAlign: "justify" }}>
                La navegación, acceso y uso por el sitio web de ELABORATS
                PREPIRINEU SL confiere la condición de usuario, por la que se
                aceptan, desde la navegación por el sitio web de ELABORATS
                PREPIRINEU SL, todas las condiciones de uso aquí establecidas
                sin perjuicio de la aplicación de la correspondiente normativa
                de obligado cumplimiento legal según el caso.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                El sitio web de ELABORATS PREPIRINEU SL proporciona gran
                diversidad de información, servicios y datos. El usuario asume
                su responsabilidad en el uso correcto del sitio web. Esta
                responsabilidad se extenderá a:
              </p>
              <ul>
                <li style={{ textAlign: "justify" }}>
                  La veracidad y licitud de las informaciones aportadas por el
                  usuario en los formularios extendidos por ELABORATS PREPIRINEU
                  SL para el acceso a ciertos contenidos o servicios ofrecidos
                  por el web.
                </li>
                <li style={{ textAlign: "justify" }}>
                  El uso de la información, servicios y datos ofrecidos por
                  ELABORATS PREPIRINEU SL contrariamente a lo dispuesto por las
                  presentes condiciones, la Ley, la moral, las buenas costumbres
                  o el orden público, o que de cualquier otro modo puedan
                  suponer lesión de los derechos de terceros o del mismo
                  funcionamiento del sitio web.
                </li>
              </ul>
              <br />
              <h6 style={{ textAlign: "justify" }}>
                Política de enlaces y exenciones de responsabilidad
              </h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL no se hace responsable del contenido de
                los sitios web a los que el usuario pueda acceder a través de
                los enlaces establecidos en su sitio web y declara que en ningún
                caso procederá a examinar o ejercitar ningún tipo de control
                sobre el contenido de otros sitios de la red. Asimismo, tampoco
                garantizará la disponibilidad técnica, exactitud, veracidad,
                validez o legalidad de sitios ajenos a su propiedad a los que se
                pueda acceder por medio de los enlaces.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL declara haber adoptado todas las medidas
                necesarias para evitar cualquier daño a los usuarios de su sitio
                web, que pudieran derivarse de la navegación por su sitio web.
                En consecuencia, ELABORATS PREPIRINEU SL no se hace responsable,
                en ningún caso, de los eventuales daños que por la navegación
                por Internet pudiera sufrir el usuario.
              </p>
              <br />
              <h6 style={{ textAlign: "justify" }}>Modificaciones</h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL se reserva el derecho a realizar las
                modificaciones que considere oportunas, sin aviso previo, en el
                contenido de su sitio web. Tanto en lo referente a los
                contenidos del sitio web, como en las condiciones de uso del
                mismo. Dichas modificaciones podrán realizarse a través de su
                sitio web por cualquier forma admisible en derecho y serán de
                obligado cumplimiento durante el tiempo en que se encuentren
                publicadas en la web y hasta que no sean modificadas válidamente
                por otras posteriores.
              </p>
              <br />
              <h6 style={{ textAlign: "justify" }}>Protección de datos</h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                De conformidad con lo establecido en la normativa vigente en
                Protección de Datos de Carácter Personal, le informamos que sus
                datos serán incorporados al sistema de tratamiento titularidad
                de ELABORATS PREPIRINEU SL con CIF B25409970 y domicilio social
                sito en PABELLÓN E No 5017, 5018, 5019 08040, MERCABARNA
                (BARCELONA), con la finalidad de poder facilitar, agilizar y
                cumplir los compromisos establecidos entre ambas partes. En
                cumplimiento con la normativa vigente, ELABORATS PREPIRINEU SL
                informa que los datos serán conservados durante el plazo
                estrictamente necesario para cumplir con los preceptos
                mencionados con anterioridad.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Le informamos que trataremos sus datos conforme a la existencia
                de su consentimiento.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL informa que procederá a tratar los datos
                de manera lícita, leal, transparente, adecuada, pertinente,
                limitada, exacta y actualizada. Es por ello que ELABORATS
                PREPIRINEU SL se compromete a adoptar todas las medidas
                razonables para que estos se supriman o rectifiquen sin dilación
                cuando sean inexactos.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                De acuerdo con los derechos que le confiere el la normativa
                vigente en protección de datos podrá ejercer los derechos de
                acceso, rectificación, limitación de tratamiento, supresión,
                portabilidad y oposición al tratamiento de sus datos de carácter
                personal así como del consentimiento prestado para el
                tratamiento de los mismos, dirigiendo su petición a la dirección
                postal indicada más arriba o al correo electrónico
                oliana@trumfosdelpirineu.com.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Podrá dirigirse a la Autoridad de Control competente para
                presentar la reclamación que considere oportuna.
              </p>
              <br />
              <h6 style={{ textAlign: "justify" }}>
                Propiedad intelectual e industrial
              </h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL por sí misma o como cesionaria, es
                titular de todos los derechos de propiedad intelectual e
                industrial de su página web, así como de los elementos
                contenidos en la misma (a título enunciativo, imágenes, sonido,
                audio, vídeo, software o textos; marcas o logotipos,
                combinaciones de colores, estructura y diseño, selección de
                materiales usados, programas de ordenador necesarios para su
                funcionamiento, acceso y uso, etc.), titularidad de ELABORATS
                PREPIRINEU SL. Serán, por consiguiente, obras protegidas como
                propiedad intelectual por el ordenamiento jurídico español,
                siéndoles aplicables tanto la normativa española y comunitaria
                en este campo, como los tratados internacionales relativos a la
                materia y suscritos por España.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Todos los derechos reservados. En virtud de lo dispuesto en la
                Ley de Propiedad Intelectual, quedan expresamente prohibidas la
                reproducción, la distribución y la comunicación pública,
                incluida su modalidad de puesta a disposición, de la totalidad o
                parte de los contenidos de esta página web, con fines
                comerciales, en cualquier soporte y por cualquier medio técnico,
                sin la autorización de ELABORATS PREPIRINEU SL.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                El usuario se compromete a respetar los derechos de Propiedad
                Intelectual e Industrial titularidad de ELABORATS PREPIRINEU SL.
                Podrá visualizar los elementos del portal e incluso imprimirlos,
                copiarlos y almacenarlos en el disco duro de su ordenador o en
                cualquier otro soporte físico siempre y cuando sea, única y
                exclusivamente, para su uso personal y privado. El usuario
                deberá abstenerse de suprimir, alterar, eludir o manipular
                cualquier dispositivo de protección o sistema de seguridad que
                estuviera instalado en las páginas de ELABORATS PREPIRINEU SL.
              </p>
              <br />
              <h6 style={{ textAlign: "justify" }}>
                Acciones legales, legislación aplicable y jurisdicción
              </h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL se reserva, asimismo, la facultad de
                presentar las acciones civiles o penales que considere oportunas
                por la utilización indebida de su sitio web y contenidos, o por
                el incumplimiento de las presentes condiciones.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                La relación entre el usuario y el prestador se regirá por la
                normativa vigente y de aplicación en el territorio español. De
                surgir cualquier controversia las partes podrán someter sus
                conflictos a arbitraje o acudir a la jurisdicción ordinaria
                cumpliendo con las normas sobre jurisdicción y competencia al
                respecto. ELABORATS PREPIRINEU SL tiene su domicilio en
                BARCELONA, España.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default SectionTerminosCondiciones;
