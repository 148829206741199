/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import GSAP animation effects
import { TweenLite, Power3 } from "gsap";

// import reactstrap components
import { Container, Row, Col } from "reactstrap";

class SectionPoliticaPrivacidad extends React.Component {
  constructor(props) {
    super(props);
    this.tweenContent = null;
    this.content = null;
  }

  componentDidMount() {
    this.tweenContent = TweenLite.from(this.content, 1, {
      opacity: 0,
      x: -100,
      ease: Power3.easeIn,
    });
  }

  render() {
    return (
      <Container>
        <div
          className="section section-white text-jsutify"
          ref={(div) => (this.content = div)}
        >
          <Row>
            <h2 className="title ml-auto mr-auto">Cláusulas Legales</h2>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto">
              <h6 style={{ textAlign: "justify" }}>Cláusula web formulario</h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                De conformidad con lo establecido en la normativa vigente en
                Protección de Datos de Carácter Personal, le informamos que sus
                datos serán incorporados al sistema de tratamiento titularidad
                de ELABORATS PREPIRINEU SL con CIF B25409970 y domicilio social
                sito en PABELLÓN E No 5017, 5018, 5019 08040, MERCABARNA
                (BARCELONA), con la finalidad de atender sus consultas. En
                cumplimiento con la normativa vigente, ELABORATS PREPIRINEU SL
                informa que los datos serán conservados durante el plazo
                estrictamente necesario para cumplir con los preceptos
                mencionados con anterioridad.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Le informamos que trataremos sus datos conforme a la existencia
                de su consentimiento.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL informa que procederá a tratar los datos
                de manera lícita, leal, transparente, adecuada, pertinente,
                limitada, exacta y actualizada. Es por ello que ELABORATS
                PREPIRINEU SL se compromete a adoptar todas las medidas
                razonables para que estos se supriman o rectifiquen sin dilación
                cuando sean inexactos.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                De acuerdo con los derechos que le confiere el la normativa
                vigente en protección de datos podrá ejercer los derechos de
                acceso, rectificación, limitación de tratamiento, supresión,
                portabilidad y oposición al tratamiento de sus datos de carácter
                personal así como del consentimiento prestado para el
                tratamiento de los mismos, dirigiendo su petición a la dirección
                postal indicada más arriba o al correo electrónico
                oliana@trumfosdelpirineu.com.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Le informamos que podrá revocar en cualquier momento el
                consentimiento prestado mandando un correo electrónico a la
                dirección: oliana@trumfosdelpirineu.com.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Podrá dirigirse a la Autoridad de Control competente para
                presentar la reclamación que considere oportuna.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                El envío de estos datos implica la aceptación de esta cláusula.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default SectionPoliticaPrivacidad;
