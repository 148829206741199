/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// cookies consent
import CookieConsent from "react-cookie-consent";

const CookieConsentModal = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Acepto"
      cookieName="CookieName1"
      style={{
        background: "#000",
        opacity: "0.7",
        justifyContent: "center",
      }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      contentStyle={{ textAlign: "center" }}
      expires={365}
      // Disabled for now
      // acceptOnScroll={true}
      // onAccept={({ acceptedByScrolling }) => {
      //   if (acceptedByScrolling) {
      //     // triggered if user scrolls past threshold
      //     alert("Se ha aceptado mediante scrolling");
      //   } else {
      //     alert("Se ha aceptado");
      //   }
      // }}
    >
      Esta web utiliza cookies para mejorar la navegación. Si continúa navegando
      se acepta el uso. Si lo desea puede consultar nuestra{" "}
      <a href="/politica-cookies" className="alert-link">
        Política de Cookies
      </a>
    </CookieConsent>
  );
};

export default CookieConsentModal;
