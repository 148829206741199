/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import GSAP animation effects
import { TweenLite, Power3 } from "gsap";

// import reactstrap components
import { Container, Row, Col } from "reactstrap";

// import cookies table
import cookiestable from "../../assets/img/tipos-cookies.png";
import cookiestable2 from "../../assets/img/tipos-cookies-2.png";

class SectionDescriptionPoliticaCookies extends React.Component {
  constructor(props) {
    super(props);
    this.tweenContent = null;
    this.content = null;
  }

  componentDidMount() {
    this.tweenContent = TweenLite.from(this.content, 1, {
      opacity: 0,
      x: -100,
      ease: Power3.easeIn,
    });
  }

  render() {
    return (
      <Container>
        <div
          className="section section-white text-jsutify"
          ref={(div) => (this.content = div)}
        >
          <Row>
            <h2 className="title ml-auto mr-auto">Política de Cookies</h2>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto">
              <p style={{ textAlign: "justify" }}>
                Conforme a lo dispuesto en el artículo 22.2 de la Ley 34/2002,
                de 11 de julio, de Servicios de la Sociedad de la Información y
                de Comercio Electrónico (LSSI-CE) vigente, ELABORATS PREPIRINEU
                viene a cumplir sus obligaciones de información sobre las
                cookies que utiliza y sus finalidades.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Una Cookie es un fichero que se descarga en su ordenador al
                acceder a determinadas páginas web. Las cookies permiten a una
                página web, entre otras cosas, almacenar y recuperar información
                sobre los hábitos de navegación de un usuario o de su equipo y,
                dependiendo de la información que contenga y de la forma en que
                utilice su equipo, pueden utilizarse para reconocer al usuario.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Las cookies son esenciales para el funcionamiento de internet,
                aportando innumerables ventajas en la prestación de servicios
                interactivos, facilitándole la navegación y usabilidad de
                nuestra web.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                La información que le proporcionamos a continuación, le ayudará
                a comprender los diferentes tipos de cookies:
              </p>
              <br />
              <img
                src={cookiestable}
                style={{ width: "80%", height: "auto" }}
                alt="..."
              />
              <br />
              <br />
              <p style={{ textAlign: "justify" }}>
                Según lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11
                de julio, de Servicios de la Sociedad de la Información y de
                Comercio Electrónico (LSSI-CE), ELABORATS PREPIRINEU SL informa
                de las cookies utilizadas en nuestra website:
              </p>
              <br />
              <img
                src={cookiestable2}
                style={{ width: "80%", height: "auto" }}
                alt="..."
              />
              <br />
              <br />
              <p style={{ textAlign: "justify" }}>
                Asimismo, ELABORATS PREPIRINEU SL informa al usuario de que
                tiene la posibilidad de configurar su navegador de modo que se
                le informe de la recepción de cookies, pudiendo, si así lo
                desea, impedir que sean instaladas en su disco duro.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                A continuación le proporcionamos los enlaces de diversos
                navegadores, a través de los cuales podrá realizar dicha
                configuración:
              </p>
              <br />
              {/* <li style={{ textAlign: "justify" }}>
                COOKIES DE ANÁLISIS propias, de sesión y persistentes que
                permiten a ELABORATS PREPIRINEU el análisis vinculado a la
                navegación realizada por el Usuario con la finalidad de llevar
                un seguimiento de uso de la página web así como realizar
                estadísticas de los contenidos más visitados, número de
                visitantes, etc.
              </li>
              <li style={{ textAlign: "justify" }}>
                COOKIES DE PERSONALIZACIÓN que permiten al Usuario una
                navegación personalizada por el sitio web, conforme a su idioma.
              </li>
              <br /> */}
              <p style={{ textAlign: "justify" }}>
                Para utilizar este sitio web no resulta necesaria la instalación
                de cookies. Usted puede configurar su navegador para que acepte,
                rechace o le notifique cuando una cookie se va a alojar en su
                ordenador. Cada navegador es diferente por ello en el caso de
                que desee configurar su navegador para bloquearlas le
                facilitamos los siguientes enlaces entre los cuales podrá
                encontrar el correspondiente a su navegador:
              </p>
              <br />
              <li style={{ textAlign: "justify" }}>
                <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                  MOZILLA FIREFOX
                </a>
              </li>
              <li style={{ textAlign: "justify" }}>
                <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">
                  GOOGLE CHROME
                </a>
              </li>
              <li style={{ textAlign: "justify" }}>
                <a href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10">
                  INTERNET
                </a>
              </li>
              <li style={{ textAlign: "justify" }}>
                <a href="http://support.apple.com/kb/ph5042">SAFARI</a>
              </li>
              <li style={{ textAlign: "justify" }}>
                <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">
                  OPERA
                </a>
              </li>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU informa al Usuario que este sitio web
                utiliza Google Analytics, almacena cookies para poder elaborar
                estadísticas sobre el tráfico y volumen de visitas de esta web.
                Al utilizar este sitio web, el Usuario está consintiendo el
                tratamiento de su información, por parte de Google. Por tanto,
                el ejercicio de cualquier derecho, en este sentido, el Usuario
                deberá hacerlo comunicándose directamente con Google.
              </p>
              {/* <br />
              <p style={{ textAlign: "justify" }}>
                Las cookies de Google Analytics, se almacenan en servidores
                ubicados en Estados Unidos. Google se compromete a no compartir
                dicha información con terceros, excepto en los casos en los que
                sea necesario para el funcionamiento del sistema o cuando la ley
                obligue a tal efecto. Según Google no guarda su dirección IP.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Google Inc. es una compañía adherida a “Privacy Shield” (escudo
                de privacidad) que garantiza que todos los datos transferidos
                serán tratados con un nivel de protección acorde a la normativa
                europea. El Usuario puede consultar información detallada a este
                respecto a través del siguiente enlace:{" "}
                <a href="https://support.google.com/analytics/answer/6004245">
                  https://support.google.com/analytics/answer/6004245
                </a>
                .
              </p> */}
              <br />
              <p style={{ textAlign: "justify" }}>
                Si el Usuario lo desea, puede utilizar el Complemento de
                inhabilitación para navegadores de Google Analytics, a través de
                cuyas instrucciones pueden rechazarse las cookies analíticas de
                dicho servicio en todos los navegadores. El Usuario puede
                consultar más información al respecto en el siguiente enlace:{" "}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                .
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default SectionDescriptionPoliticaCookies;
