/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/
import React from "react";

// react responsive
import MediaQuery from "react-responsive";

// import GSAP + ScrollMagic for animations
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { Power3 } from "gsap";
import ScrollMagic from "scrollmagic";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class SectionConocenos extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }

  componentDidMount() {
    // create a scene
    new ScrollMagic.Scene({
      triggerElement: "#conocenos-lp",
      duration: 200, // the scene should last for a scroll distance of 100px
      offset: 100, // start this scene after scrolling for 50px
    })
      .setTween("#conocenos-tween", 1, {
        opacity: 1,
        y: 30,
        ease: Power3.easeOut,
      })
      // .setPin("#producto1-lp") // pins the element for the the scene's duration
      .addTo(this.controller); // assign the scene to the controller
    // .addIndicators();
  }

  render() {
    return (
      <>
        <MediaQuery maxDeviceWidth={1024}>
          <div className="section section-dark text-center" id="conocenos-lp">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <h2 className="title">Conócenos</h2>
                  <h5
                    className="description"
                    id="conocenos-tween"
                    style={{ opacity: "0", color: "white" }}
                  >
                    Trumfos del Pirineu es una empresa agrícola con muchos años
                    de experiencia en el sector de la patata: primero como
                    agricultores y después como distribuidores. ¿Quieres
                    descubrir nuestras raíces?
                  </h5>
                  <br />
                  <Button
                    className="btn-round btn-gold"
                    id="conocenos-tween"
                    style={{ opacity: "0" }}
                    color="info"
                    onClick={(event) => (window.location.href = "/empresa")}
                  >
                    EMPRESA
                  </Button>
                </Col>
              </Row>
              <br />
              <br />
            </Container>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1025}>
          <div
            className="section section-dark text-center"
            id="conocenos-lp"
            style={{
              backgroundImage:
                "url(" +
                require("../../assets/img/old/camp-patates-vell-4.jpg") +
                ")",
              backgroundPosition: "center top",
              backgroundSize: "100% auto",
            }}
          >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <h2 className="title">Conócenos</h2>
                  <h5
                    className="description"
                    id="conocenos-tween"
                    style={{ opacity: "0", color: "white" }}
                  >
                    Trumfos del Pirineu es una empresa agrícola con muchos años
                    de experiencia en el sector de la patata: primero como
                    agricultores y después como distribuidores. ¿Quieres
                    descubrir nuestras raíces?
                  </h5>
                  <br />
                  <Button
                    className="btn-round btn-gold"
                    id="conocenos-tween"
                    style={{ opacity: "0" }}
                    color="info"
                    onClick={(event) => (window.location.href = "/empresa")}
                  >
                    EMPRESA
                  </Button>
                </Col>
              </Row>
              <br />
              <br />
            </Container>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default SectionConocenos;
