/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// video background
import videobg from "../../assets/video/video_bg.mp4";

// scrolling
import { Link } from "react-scroll";

const LandingPageHeader = () => {
  // let pageHeader = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth < 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  return (
    <div className="v-header container">
      <div className="header-overlay"></div>
      <div className="fullscreen-video-wrap">
        <video src={videobg} autoPlay loop muted></video>
      </div>
      <div className="filter" />
      <Container>
        <div className="motto text-center">
          <h1>Trumfos del Pirineu</h1>
          <h3>Patata Tradicional de Calidad</h3>
          <br />
          <Link
            activeclass="btn-round mr-1"
            to="conocenos-lp"
            spy={true}
            smooth={true}
            offset={-70}
            duration={800}
          >
            <Button
              activeclass="btn-round mr-1"
              className="mr-1"
              color="neutral"
              target="_blank"
              outline
            >
              Conócenos
            </Button>
          </Link>
          <Link
            activeClass="btn-round mr-1"
            to="productos-lp"
            spy={true}
            smooth={true}
            offset={-70}
            duration={800}
          >
            <Button className="mr-1" color="neutral" target="_blank" outline>
              Productos
            </Button>
          </Link>
        </div>
      </Container>
      <br />
    </div>
  );
};

export default LandingPageHeader;
