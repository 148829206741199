/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

// import GSAP animation effects
import { Power3, TimelineLite } from "gsap";

// import image photos of the products
import cajacebolla1 from "../../assets/img/potatoes-boxes/DSC_0151-2.jpg";
import cajacebolla2 from "../../assets/img/potatoes-boxes/DSC_0179-2.jpg";

class CebollasCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.myTweens = new TimelineLite({ paused: true });
    this.products = [];
  }

  componentDidMount() {
    this.myTweens
      .staggerFrom(
        this.products,
        0.8,
        { opacity: 0, y: 40, ease: Power3.easeOut },
        0.2
      )
      .play();
  }

  render() {
    return (
      <div className="section text-center">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto">
              <h2 className="title">Descubre nuestra gama de cebollas</h2>
              <h5 className="ml-auto mr-auto">
                Aquí podrás encontrar nuestros distintos envases para las
                cebollas de máxima calidad
              </h5>
            </Col>
          </Row>
          <blockquote className="blockquote">
            <h5 className="mb-0">Cajas</h5>
          </blockquote>
          <Row>
            <div
              ref={(div) => (this.products[0] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col md={10}>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={cajacebolla1} alt="producto1" />
                  <CardBody>
                    <CardTitle>Cebollas Trumfos 10 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Caja de cebollas de distintas variedades
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <div
              ref={(div) => (this.products[0] = div)}
              style={{ marginTop: "50px" }}
              className="ml-auto mr-auto"
            >
              <Col md={10}>
                <Card style={{ width: "15rem" }} className="text-left">
                  <CardImg top src={cajacebolla2} alt="producto1" />
                  <CardBody>
                    <CardTitle>Cebollas Trumfos 7 kg</CardTitle>
                    {/* <CardHeader>
                    <div className="nav-tabs-navigation">
                      <div className="nav-tabs-wrapper">
                        <Nav tabs>
                          <NavItem>
                            <NavLink href="#info1" active>
                              Info
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="#variedad1">Variedad</NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                    </div>
                  </CardHeader> */}

                    <CardText>
                      Caja de cebollas de distintas variedades. En esta caja
                      encontrarás la cebolla mejor seleccionada
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CebollasCatalog;
