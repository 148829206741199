/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardColumns,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
} from "reactstrap";

// import GSAP animation effects
import { Power3, TimelineLite } from "gsap";

// import carousel news React component
import CarouselNews from "./CarouselNews.js";

// react responsive
import MediaQuery from "react-responsive";

// import images to Carousel 1
import imageCarousel1 from "../../assets/img/news/heading-magazinepublicacions.jpg";
import magazinepublicacions1 from "../../assets/img/news/reportatge-magazinepublicacions-1.jpg";
import magazinepublicacions2 from "../../assets/img/news/reportatge-magazinepublicacions-2.jpg";
// import images to Carousel 2
import imageCarousel2 from "../../assets/img/news/agenda-del-pages-2018-400px.png";
import agendaDelPages from "../../assets/img/news/agenda-del-pages-2018-edited.png";
// import images to Carousel 3
import imageCarousel3 from "../../assets/img/news/viure-als-pirineus-402px.png";
import viureAlsPirineus1 from "../../assets/img/news/viure-als-pirineus-1.png";
import viureAlsPirineus2 from "../../assets/img/news/viure-als-pirineus-2.png";

const imagesCarousel1 = [
  { src: magazinepublicacions1 },
  { src: magazinepublicacions2 },
];

const imagesCarousel2 = [{ src: agendaDelPages }];

const imagesCarousel3 = [
  { src: viureAlsPirineus1 },
  { src: viureAlsPirineus2 },
];

class SectionNoticias extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.myTweens = new TimelineLite({ paused: true });
    this.news = [];
  }

  onClickCarousel1 = () => {
    this.child.current.images = imagesCarousel1;
    this.child.current.toggleModal();
  };

  onClickCarousel2 = () => {
    this.child.current.images = imagesCarousel2;
    this.child.current.toggleModal();
  };

  onClickCarousel3 = () => {
    this.child.current.images = imagesCarousel3;
    this.child.current.toggleModal();
  };

  componentDidMount() {
    this.myTweens
      .staggerFrom(
        this.news,
        0.8,
        { opacity: 0, y: 40, ease: Power3.easeOut },
        0.2
      )
      .play();
  }

  render() {
    return (
      <>
        <MediaQuery maxDeviceWidth={1024}>
          <div className="section section-white text-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="15">
                  <h2 className="title">Actualidad de la empresa</h2>
                </Col>
              </Row>
              <Row>
                <br />
                <Col>
                  <CardColumns className="d-flex justify-content-center">
                    <Col>
                      <div
                        className="text-center"
                        ref={(div) => (this.news[0] = div)}
                      >
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={imageCarousel1}
                            alt="turismecat"
                            onClick={this.onClickCarousel1}
                            style={{ cursor: "pointer" }}
                          />
                          <CardTitle>Reportaje a Turisme Catalunya</CardTitle>
                          <CardBody>
                            <CardText>Diciembre 2019</CardText>
                          </CardBody>
                          <CarouselNews ref={this.child} />
                        </Card>
                      </div>
                    </Col>
                  </CardColumns>
                  <CardColumns>
                    <Col>
                      <div
                        className="text-center"
                        ref={(div) => (this.news[1] = div)}
                      >
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={imageCarousel2}
                            alt="turismecat"
                            onClick={this.onClickCarousel2}
                            style={{ cursor: "pointer" }}
                          />
                          <CardTitle>Reportaje a Agenda del Pagès</CardTitle>
                          <CardBody>
                            <CardText>2018</CardText>
                          </CardBody>
                          <CarouselNews ref={this.child} />
                        </Card>
                      </div>
                    </Col>
                  </CardColumns>
                  <CardColumns>
                    <Col>
                      <div
                        className="text-center"
                        ref={(div) => (this.news[2] = div)}
                      >
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={imageCarousel3}
                            alt="turismecat"
                            onClick={this.onClickCarousel3}
                            style={{ cursor: "pointer" }}
                          />
                          <CardTitle>Reportaje a Viure als Pirineus</CardTitle>
                          <CardBody>
                            <CardText>Marzo 2013</CardText>
                          </CardBody>
                          <CarouselNews ref={this.child} />
                        </Card>
                      </div>
                    </Col>
                  </CardColumns>
                  <CarouselNews />
                </Col>
              </Row>
              <br />
            </Container>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1025}>
          <div className="section section-white text-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="15">
                  <h2 className="title">Actualidad de la empresa</h2>
                </Col>
              </Row>
              <Row>
                <br />
                <Col>
                  <CardColumns className="d-flex justify-content-center">
                    <Col>
                      <div
                        className="text-center"
                        ref={(div) => (this.news[0] = div)}
                      >
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={imageCarousel1}
                            alt="turismecat"
                            onClick={this.onClickCarousel1}
                            style={{ cursor: "pointer" }}
                          />
                          <CardTitle>Reportaje a Turisme Catalunya</CardTitle>
                          <CardBody>
                            <CardText>Diciembre 2019</CardText>
                          </CardBody>
                          <CarouselNews ref={this.child} />
                        </Card>
                      </div>
                    </Col>
                    <Col>
                      <div
                        className="text-center"
                        ref={(div) => (this.news[1] = div)}
                      >
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={imageCarousel2}
                            alt="turismecat"
                            onClick={this.onClickCarousel2}
                            style={{ cursor: "pointer" }}
                          />
                          <CardTitle>Reportaje a Agenda del Pagès</CardTitle>
                          <CardBody>
                            <CardText>2018</CardText>
                          </CardBody>
                          <CarouselNews ref={this.child} />
                        </Card>
                      </div>
                    </Col>
                    <Col>
                      <div
                        className="text-center"
                        ref={(div) => (this.news[2] = div)}
                      >
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={imageCarousel3}
                            alt="turismecat"
                            onClick={this.onClickCarousel3}
                            style={{ cursor: "pointer" }}
                          />
                          <CardTitle>Reportaje a Viure als Pirineus</CardTitle>
                          <CardBody>
                            <CardText>Marzo 2013</CardText>
                          </CardBody>
                          <CarouselNews ref={this.child} />
                        </Card>
                      </div>
                    </Col>
                  </CardColumns>
                  <CarouselNews />
                </Col>
              </Row>
              <br />
            </Container>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default SectionNoticias;
