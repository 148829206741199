import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

class CarouselNews extends React.Component {
  state = { modalIsOpen: false };
  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  };
  render() {
    const { modalIsOpen } = this.state;

    return (
      <div>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel
                views={this.images}
                styles={{
                  footer: base => ({
                    ...base,
                    background: "none !important",
                    color: "#666",
                    padding: 0,
                    paddingTop: 20,
                    position: "static",

                    "& a": {
                      color: "black"
                    }
                  }),
                  header: base => ({
                    ...base,
                    background: "none !important",
                    padding: 0,
                    paddingBottom: 10,
                    position: "static"
                  }),
                  headerClose: base => ({
                    ...base,
                    color: "#666",

                    ":hover": { color: "#DE350B" }
                  }),
                  view: base => ({
                    ...base,
                    height: "auto",
                    width: "auto",
                    // maxHeight: 480,
                    overflow: "hidden"
                  })
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    );
  }
}

export default CarouselNews;
