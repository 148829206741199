/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/
import React from "react";

// core components
import LandingPageNavbar from "../components/Navbars/LandingPageNavbar.js";
import ContactoHeader from "../components/Headers/ContactoHeader.js";
import EmpresaFooter from "../components/Footers/EmpresaFooter.js";

// contacto page sections
import SectionDescription from "./contacto-sections/SectionDescription.js";
// import SectionGoogleMaps from "./contacto-sections/SectionGoogleMaps.js";
import SectionContactForm from "./contacto-sections/SectionContactForm.js";

const ContactoPage = () => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <LandingPageNavbar />
      <ContactoHeader />
      <SectionDescription />
      <SectionContactForm />
      <EmpresaFooter />
    </>
  );
};

export default ContactoPage;
