/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// react responsive
import MediaQuery from "react-responsive";

// import GSAP + ScrollMagic for animations
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { TweenLite, Power3 } from "gsap";
// import ScrollMagic from "scrollmagic";

// import reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// import images
// import exoticosection from "../../assets/img/patata-campo-2.jpg";
import durian1 from "../../assets/img/durian1.jpg";

class SectionExoticos extends React.Component {
  constructor(props) {
    super(props);
    this.tweenContent = null;
    this.tweenButton = null;
    this.exoticoSection = null;
    this.discoverButton = null;
  }

  componentDidMount() {
    this.tweenContent = TweenLite.from(this.exoticoSection, 1, {
      opacity: 0,
      x: -100,
      ease: Power3.easeIn,
      delay: 1.5
    });

    this.tweenButton = TweenLite.from(this.discoverButton, 1, {
      opacity: 0,
      x: 100,
      ease: Power3.easeIn,
      delay: 1.5
    });

    // // create a scene
    // new ScrollMagic.Scene({
    //   triggerElement: "#exoticos-section",
    //   duration: 150, // the scene should last for a scroll distance of 100px
    //   offset: -100 // start this scene after scrolling for 50px
    // })
    //   .setTween("#exoticos-area", 1, {
    //     opacity: 1,
    //     x: 100,
    //     ease: Power3.easeOut
    //   })
    //   // .setPin("#producto1-lp") // pins the element for the the scene's duration
    //   .addTo(this.controller) // assign the scene to the controller
    //   .addIndicators();

    // // create a scene
    // new ScrollMagic.Scene({
    //   triggerElement: "#exoticos-section",
    //   duration: 150, // the scene should last for a scroll distance of 100px
    //   offset: -100 // start this scene after scrolling for 50px
    // })
    //   .setTween("#exoticos-button", 1, {
    //     opacity: 1,
    //     x: -100,
    //     ease: Power3.easeOut
    //   })
    //   // .setPin("#producto1-lp") // pins the element for the the scene's duration
    //   .addTo(this.controller) // assign the scene to the controller
    //   .addIndicators();
  }

  render() {
    return (
      <>
        <Container fluid={true} className="section-dark" id="exoticos-section">
          <Row>
            <MediaQuery maxWidth={1024}>
              <Col sm={{ size: "auto", offset: 0 }}>
                <div ref={div => (this.exoticoSection = div)}>
                  <img
                    id="exoticos-area"
                    src={durian1}
                    alt="patata-left"
                    className="image-productos-patata"
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "70%"
                    }}
                  />
                </div>
              </Col>
            </MediaQuery>
            <MediaQuery minWidth={1025}>
              <Col sm={{ size: "auto", offset: 2 }}>
                <div ref={div => (this.exoticoSection = div)}>
                  <img
                    id="exoticos-area"
                    src={durian1}
                    alt="patata-left"
                    className="image-productos-patata"
                  />
                </div>
              </Col>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
              <Col
                sm={{ size: "auto", offset: 0 }}
                style={{ paddingTop: "2rem" }}
              >
                <div ref={div => (this.discoverButton = div)}>
                  <Row>
                    <h1
                      id="exoticos-area"
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    >
                      Productos Exóticos
                    </h1>
                  </Row>
                  <br />
                  <Row>
                    <h4
                      id="exoticos-area"
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center"
                      }}
                    >
                      Los productos más exóticos a tu alcance.
                    </h4>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Button
                      // id="patatas-button"
                      className="btn-round btn-gold"
                      color="info"
                      onClick={event =>
                        (window.location.href = "/productos/exoticos")
                      }
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        textAlign: "center"
                      }}
                    >
                      DESCUBRE MÁS
                    </Button>
                  </Row>
                  <br />
                  <br />
                </div>
              </Col>
            </MediaQuery>
            <MediaQuery minWidth={1025}>
              <Col
                sm={{ size: "auto", offset: 0 }}
                style={{ paddingTop: "2rem", paddingLeft: "9rem" }}
              >
                <div ref={div => (this.discoverButton = div)}>
                  <Row>
                    <h1 id="exoticos-area">Productos Exóticos</h1>
                  </Row>
                  <br />
                  <Row>
                    <h4 id="exoticos-area">
                      Los productos más exóticos a tu alcance.
                    </h4>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Button
                      // id="patatas-button"
                      className="btn-round btn-gold"
                      color="info"
                      onClick={event =>
                        (window.location.href = "/productos/exoticos")
                      }
                    >
                      DESCUBRE MÁS
                    </Button>
                  </Row>
                </div>
              </Col>
            </MediaQuery>
          </Row>
        </Container>
      </>
    );
  }
}

export default SectionExoticos;
