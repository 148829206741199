/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyC0SICNCZm8bzNZzJpY2eq9R60dEGT93DI",
  authDomain: "elaborats-prepirineu-webpage.firebaseapp.com",
  databaseURL: "https://elaborats-prepirineu-webpage.firebaseio.com",
  projectId: "elaborats-prepirineu-webpage",
  storageBucket: "elaborats-prepirineu-webpage.appspot.com",
  messagingSenderId: "767349148733",
  appId: "1:767349148733:web:7d0327742760f91ac6ae54",
  measurementId: "G-QTCHGYE7MK",
};

const firebaseConf = firebase.initializeApp(firebaseConfig);
const db = firebaseConf.firestore();
export default db;
