/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import GSAP animation effects
import { TweenLite, Power3 } from "gsap";

// import reactstrap components
import { Container, Row, Col } from "reactstrap";

class SectionDescriptionPoliticaRRSS extends React.Component {
  constructor(props) {
    super(props);
    this.tweenContent = null;
    this.content = null;
  }

  componentDidMount() {
    this.tweenContent = TweenLite.from(this.content, 1, {
      opacity: 0,
      x: -100,
      ease: Power3.easeIn,
    });
  }

  render() {
    return (
      <Container>
        <div
          className="section section-white text-jsutify"
          ref={(div) => (this.content = div)}
        >
          <Row>
            <h2 className="title ml-auto mr-auto">
              Política de Privacidad Redes Sociales
            </h2>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto">
              <p style={{ textAlign: "justify" }}>
                De conformidad con lo establecido en la normativa vigente en
                Protección de Datos de Carácter Personal (RGPD) y la Ley
                34/2002, de 11 de julio, de Servicios de la Sociedad de la
                Información y de Comercio Electrónico (LSSI-CE), ELABORATS
                PREPIRINEU SL informa a los usuarios, que ha procedido a crear
                un perfil en las Redes Sociales Facebook e Instagram, con la
                finalidad principal de publicitar sus productos y servicios.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Datos de ELABORATS PREPIRINEU SL:
              </p>
              <br />
              <ul>
                <li style={{ textAlign: "justify" }}>CIF: B25409970</li>
                <li style={{ textAlign: "justify" }}>
                  DIRECCIÓN: POLÍGONO FIGOLERS Nº3 25790, OLIANA (LLEIDA)
                </li>
                <li style={{ textAlign: "justify" }}>
                  CORREO ELECTRÓNICO: oliana@trumfosdelpirineu.com
                </li>
                <li style={{ textAlign: "justify" }}>
                  DOMINIO WEB:{" "}
                  <a href="www.trumfosdelpirineu.com">
                    www.trumfosdelpirineu.com
                  </a>
                </li>
              </ul>
              <br />
              <p style={{ textAlign: "justify" }}>
                El usuario dispone de un perfil en la misma Red Social y ha
                decidido unirse a la página creada por ELABORATS PREPIRINEU SL,
                mostrando así interés en la información que se publicite en la
                Red. Al unirse a nuestra página, nos facilita su consentimiento
                para el tratamiento de aquellos datos personales publicados en
                su perfil.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                El usuario puede acceder en todo momento a las políticas de
                privacidad de la propia Red Social, así como configurar su
                perfil para garantizar su privacidad.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL tiene acceso y trata aquella información
                pública del usuario, en especial, su nombre de contacto. Estos
                datos, sólo son utilizados dentro de la propia Red Social. No
                son incorporados a ningún sistema de tratamiento.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                En relación a los derechos de acceso, rectificación, limitación
                de tratamiento, supresión, portabilidad y oposición al
                tratamiento de sus datos de carácter personal, de los que usted
                dispone y que pueden ser ejercitados ante ELABORATS PREPIRINEU
                SL, de acuerdo con el RGPD, debe tener en cuenta los siguientes
                matices:
              </p>
              <br />
              <ul>
                <li style={{ textAlign: "justify" }}>
                  Derecho de Acceso: Es el derecho del usuario a obtener
                  información sobre sus datos concretos de carácter personal y
                  del tratamiento que se haya realizado o realice, así como de
                  la información disponible sobre el origen de dichos datos y
                  las comunicaciones realizadas o previstas de los mismos.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Derecho de Rectificación: Es el derecho del afectado a que se
                  modifiquen los datos que resulten ser inexactos o incompletos.
                  Sólo podrá satisfacerse en relación a aquella información que
                  se encuentre bajo el control de ELABORATS PREPIRINEU SL, por
                  ejemplo, eliminar comentarios publicados en la propia página,
                  imágenes o contenidos web donde consten datos de carácter
                  personal del usuario.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Derecho a la Limitación de tratamiento: Es el derecho a que se
                  limiten los fines del tratamiento previstos de forma original
                  por el responsable del tratamiento.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Derecho de Supresión: Es el derecho a suprimir los datos de
                  carácter personal del usuario, a excepción de lo previsto en
                  el propio RGPD o en otras normativas aplicables que determinen
                  la obligatoriedad de la conservación de los mismos, en tiempo
                  y forma.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Derecho de portabilidad: El derecho a recibir los datos
                  personales que el usuario, haya facilitado, en un formato
                  estructurado, de uso común y lectura mecánica, y a
                  transmitirlos a otro responsable.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Derecho de Oposición: Es el derecho del usuario a que no se
                  lleve a cabo el tratamiento de sus datos de carácter personal
                  o se cese el tratamiento de los mismos por parte de ELABORATS
                  PREPIRINEU SL.
                </li>
              </ul>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU realizará las siguientes actuaciones:
              </p>
              <br />
              <ul>
                <li style={{ textAlign: "justify" }}>
                  Acceso a la información pública del perfil.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Publicación en el perfil del Usuario de toda aquella
                  información ya publicada en la página de ELABORATS PREPIRINEU.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Enviar mensajes personales e individuales a través de los
                  canales de la Red Social.
                </li>
                <li style={{ textAlign: "justify" }}>
                  Actualizaciones del estado de la página que se publicarán en
                  el perfil del Usuario.
                </li>
              </ul>
              <br />
              <p style={{ textAlign: "justify" }}>
                El Usuario siempre puede controlar sus conexiones, eliminar los
                contenidos que dejen de interesarle y restringir con quién
                comparte sus conexiones, para ello deberá acceder a su
                configuración de privacidad.
              </p>
              <br />
              <h6 style={{ textAlign: "justify" }}>Publicaciones</h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                El usuario, una vez unido a la página de ELABORATS PREPIRINEU
                SL, podrá publicar en ésta última comentarios, enlaces, imágenes
                o fotografías o cualquier otro tipo de contenido multimedia
                soportado por la Red Social. El usuario, en todos los casos,
                debe ser el titular de los mismos, gozar de los derechos de
                autor y de propiedad intelectual o contar con el consentimiento
                de los terceros afectados. Se prohíbe expresamente cualquier
                publicación en la página, ya sean textos, gráficos, fotografías,
                vídeos, etc. que atenten o sean susceptibles de atentar contra
                la moral, la ética, el buen gusto o el decoro, y/o que
                infrinjan, violen o quebranten los derechos de propiedad
                intelectual o industrial, el derecho a la imagen o la Ley. En
                estos casos, ELABORATS PREPIRINEU SL se reserva el derecho a
                retirar de inmediato el contenido, pudiendo solicitar el bloqueo
                permanente del usuario.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL no se hará responsable de los contenidos
                que libremente ha publicado un usuario.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                El usuario debe tener presente que sus publicaciones serán
                conocidas por los otros usuarios, por lo que él mismo es el
                principal responsable de su privacidad.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                Las imágenes que puedan publicarse en la página no serán
                almacenadas en ningún sistema de tratamiento por parte de
                ELABORATS PREPIRINEU SL, pero sí que permanecerán en la Red
                Social.
              </p>
              <br />
              <h6 style={{ textAlign: "justify" }}>Concursos y promociones</h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL se reserva el derecho a realizar
                concursos y promociones, en los que podrá participar el usuario
                unido a su página. Las bases de cada uno de ellos, cuando se
                utilice para ello la plataforma de la Red Social, serán
                publicadas en la misma. Cumpliendo siempre con la LSSI- CE y con
                cualquier otra norma que le sea de aplicación.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                La Red Social no patrocina, avala ni administra, de modo alguno,
                ninguna de nuestras promociones, ni está asociada a ninguna de
                ellas.
              </p>
              <br />
              <h6 style={{ textAlign: "justify" }}>Publicidad</h6>
              <br />
              <p style={{ textAlign: "justify" }}>
                ELABORATS PREPIRINEU SL utilizará la Red Social para publicitar
                sus productos y servicios, en todo caso, si decide tratar sus
                datos de contacto para realizar acciones directas de prospección
                comercial, será siempre, cumpliendo con las exigencias legales
                del RGPD y de la LSSI-CE.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                No se considerará publicidad el hecho de recomendar a otros
                usuarios la página de ELABORATS PREPIRINEU SL para que también
                ellos puedan disfrutar de las promociones o estar informados de
                su actividad.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                A continuación detallamos el enlace a la política de privacidad
                de la Red Social:
              </p>
              <br />
              <ul>
                <li style={{ textAlign: "justify" }}>
                  Facebook:{" "}
                  <a href="https://www.facebook.com/help/323540651073243/">
                    https://www.facebook.com/help/323540651073243
                  </a>
                </li>
                <li style={{ textAlign: "justify" }}>
                  Instragram:{" "}
                  <a href="https://help.instagram.com/402411646841720">
                    https://help.instagram.com/402411646841720
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default SectionDescriptionPoliticaRRSS;
