/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/
/*eslint-disable*/
import React from "react";

// import logo brand
import logoTrumfos from "../../assets/img/logo-web.svg";

// reactstrap components
import { Row, Container } from "reactstrap";

const LandingPageFooter = () => {
  return (
    <footer className="footer footer-black">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <img
                  alt="logo"
                  className="logo-trumfos-footer"
                  src={logoTrumfos}
                />
              </li>
              <li>
                <a href="/aviso-legal">Aviso Legal</a>
              </li>
              <li>
                <a href="/politica-cookies">Política de Cookies</a>
              </li>
              <li>
                <a href="/politica-rrss">Política Redes Sociales</a>
              </li>
              <li>
                <span className="copyright" style={{ textAlign: "center" }}>
                  © {new Date().getFullYear()}, with{" "}
                  <i className="fa fa-heart heart" /> by Francesc Reig
                </span>
              </li>
            </ul>

            {/* <div className="credits ml-auto">
            </div> */}
          </nav>
        </Row>
      </Container>
    </footer>
  );
};

export default LandingPageFooter;
