/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/
import React from "react";

// import GSAP + ScrollMagic for animations
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { Power3 } from "gsap";
import ScrollMagic from "scrollmagic";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// Embed instagram posts
import InstagramEmbed from "react-instagram-embed";
import MediaQuery from "react-responsive";

class SectionInstagram extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }

  componentDidMount() {
    // create a scene
    new ScrollMagic.Scene({
      triggerElement: "#instagram-lp",
      duration: 300, // the scene should last for a scroll distance of 100px
      offset: 500, // start this scene after scrolling for 50px
    })
      .setTween("#instagram-tween", 1, {
        opacity: 1,
        y: 30,
        ease: Power3.easeOut,
      })
      // .setPin("#producto1-lp") // pins the element for the the scene's duration
      .addTo(this.controller); // assign the scene to the controller
    // .addIndicators();
  }

  render() {
    return (
      <>
        <div className="section landing-section" id="instagram-lp">
          <Container>
            <MediaQuery maxDeviceWidth={1024}>
              <br />
              <Row>
                <div className="ml-auto mr-auto">
                  <Col>
                    <InstagramEmbed
                      url="https://www.instagram.com/p/BS_Estsl4vD/"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                      onLoading={() => {}}
                      onSuccess={() => {}}
                      onAfterRender={() => {}}
                      onFailure={() => {}}
                    />
                  </Col>
                </div>
                <div className="ml-auto mr-auto">
                  <Col>
                    <InstagramEmbed
                      url="https://www.instagram.com/p/BS_FB81F-zV/"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                      onLoading={() => {}}
                      onSuccess={() => {}}
                      onAfterRender={() => {}}
                      onFailure={() => {}}
                    />
                  </Col>
                </div>
              </Row>
            </MediaQuery>
            <MediaQuery minDeviceWidth={1025}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Row>
                <div className="ml-auto mr-auto">
                  <Col>
                    <InstagramEmbed
                      id="instagram-tween"
                      style={{ opacity: "0" }}
                      url="https://www.instagram.com/p/BS_Estsl4vD/"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                      onLoading={() => {}}
                      onSuccess={() => {}}
                      onAfterRender={() => {}}
                      onFailure={() => {}}
                    />
                  </Col>
                </div>
                <div className="ml-auto mr-auto">
                  <Col>
                    <InstagramEmbed
                      id="instagram-tween"
                      style={{ opacity: "0" }}
                      url="https://www.instagram.com/p/BS_FB81F-zV/"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                      onLoading={() => {}}
                      onSuccess={() => {}}
                      onAfterRender={() => {}}
                      onFailure={() => {}}
                    />
                  </Col>
                </div>
              </Row>
            </MediaQuery>
          </Container>
        </div>
      </>
    );
  }
}

export default SectionInstagram;
