/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/
import React from "react";

// react responsive
import MediaQuery from "react-responsive";

// import GSAP + ScrollMagic for animations
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { Power3 } from "gsap";
import ScrollMagic from "scrollmagic";

//import product images
// import photoproduct1 from "../../assets/img/faces/clem-onojeghuo-3.jpg";
// import potatoes1 from "../../assets/img/potatoes-personal/patata-saco-ansamora2.jpg";
// import onion1 from "../../assets/img/potatoes-personal/onion2.jpg";
// import dragonfruit1 from "../../assets/img/dragonfruit2.jpg";
import potatoe1 from "../../assets/img/landingpage/potatoe-heart-1.jpg";
import onion1 from "../../assets/img/landingpage/onions-landingpage-1.jpg";
import garlic1 from "../../assets/img/landingpage/garlic-ladingpage-1.jpg";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class SectionProductos extends React.Component {
  constructor(props) {
    super(props);
    this.controller = new ScrollMagic.Controller();
  }

  componentDidMount() {
    // create a scene
    new ScrollMagic.Scene({
      triggerElement: "#productos-lp",
      duration: 200, // the scene should last for a scroll distance of 100px
      offset: 100, // start this scene after scrolling for 50px
    })
      .setTween("#productos-tween", 1, {
        opacity: 1,
        y: 30,
        ease: Power3.easeOut,
      })
      // .setPin("#producto1-lp") // pins the element for the the scene's duration
      .addTo(this.controller); // assign the scene to the controller
    // .addIndicators();

    // create a scene
    new ScrollMagic.Scene({
      triggerElement: "#productos-lp",
      duration: 600, // the scene should last for a scroll distance of 100px
      offset: 50, // start this scene after scrolling for 50px
    })
      .setTween("#left-animation", 1, {
        opacity: 1,
        y: 50,
        ease: Power3.easeOut,
      })
      // .setPin("#producto1-lp") // pins the element for the the scene's duration
      .addTo(this.controller); // assign the scene to the controller
    // .addIndicators();

    // create a scene
    new ScrollMagic.Scene({
      triggerElement: "#productos-lp",
      duration: 600, // the scene should last for a scroll distance of 100px
      offset: 50, // start this scene after scrolling for 50px
    })
      .setTween("#producto1-lp", 1, {
        opacity: 1,
        y: -100,
        ease: Power3.easeOut,
        delay: 0.5,
      })
      // .setPin("#producto1-lp") // pins the element for the the scene's duration
      .addTo(this.controller); // assign the scene to the controller

    // create a scene
    new ScrollMagic.Scene({
      triggerElement: "#productos-lp",
      duration: 600, // the scene should last for a scroll distance of 100px
      offset: 50, // start this scene after scrolling for 50px
    })
      .setTween("#producto2-lp", 2, {
        opacity: 1,
        x: 250,
        ease: Power3.easeOut,
        delay: 1,
      })
      // .setPin("#producto1-lp") // pins the element for the the scene's duration
      .addTo(this.controller); // assign the scene to the controller

    // create a scene
    new ScrollMagic.Scene({
      triggerElement: "#productos-lp",
      duration: 500, // the scene should last for a scroll distance of 100px
      offset: 50, // start this scene after scrolling for 50px
    })
      .setTween("#producto3-lp", 2, {
        opacity: 1,
        x: -50,
        ease: Power3.easeOut,
        delay: 1,
      })
      // .setPin("#producto1-lp") // pins the element for the the scene's duration
      .addTo(this.controller); // assign the scene to the controller
  }

  render() {
    return (
      <>
        <div
          className="section productos-lp"
          id="productos-lp"
          // style={{ height: "100vh" }}
        >
          <Container>
            <MediaQuery maxDeviceWidth={1024}>
              <Row>
                <Col>
                  <h2 className="title">Productos</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="outer-left">
                    <div className="inner-left">
                      <h3
                        id="productos-tween"
                        style={{
                          opacity: "0",
                          textAlign: "center",
                        }}
                      >
                        Cuidamos nuestros productos y te cuidamos a ti
                      </h3>
                      <br />
                      <br />
                      <p
                        id="productos-tween"
                        style={{
                          opacity: "0",
                          textAlign: "center",
                        }}
                      >
                        Escogemos la variedad idónea, en el momento óptimo de
                        recolección para que puedas disfrutar de un producto de
                        primera calidad. Podrás encontrar las mejores patatas,
                        cebollas, ajos y también los productos exóticos más
                        fascinantes. Descubre las marcas que nos identifican.
                      </p>
                      <br />
                      <br />
                      <Button
                        className="btn-round btn-gold"
                        id="productos-tween"
                        color="info"
                        style={{
                          position: "relative",
                          opacity: "0",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        onClick={(event) =>
                          (window.location.href = "/productos")
                        }
                      >
                        PRODUCTOS
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </MediaQuery>
            <MediaQuery minDeviceWidth={1025}>
              <Row>
                <Col>
                  <h2 className="title">Productos</h2>
                </Col>
              </Row>
              <Row>
                <br />
                <Col>
                  <div className="outer-left">
                    <div className="inner-left">
                      <h3 id="left-animation" style={{ paddingLeft: "50px" }}>
                        Cuidamos nuestros productos y te cuidamos a ti
                      </h3>
                      <p id="left-animation" style={{ paddingLeft: "50px" }}>
                        Escogemos la variedad idónea, en el momento óptimo de
                        recolección para que puedas disfrutar de un producto de
                        primera calidad. Podrás encontrar las mejores patatas,
                        cebollas y ajos. Descubre las marcas que nos
                        identifican.
                      </p>
                      <Button
                        id="left-animation"
                        className="btn-round btn-gold"
                        color="info"
                        style={{
                          position: "relative",
                          transform: "translateX(150%)",
                        }}
                        onClick={(event) =>
                          (window.location.href = "/productos")
                        }
                      >
                        PRODUCTOS
                      </Button>
                    </div>
                  </div>
                </Col>
                <div className="outer-right">
                  <div className="inner-right">
                    <img id="producto1-lp" alt="exotic" src={potatoe1} />
                  </div>
                </div>
              </Row>
              <img id="producto2-lp" alt="potatoe" src={garlic1} />
              <img id="producto3-lp" alt="onion" src={onion1} />
            </MediaQuery>
          </Container>
        </div>
      </>
    );
  }
}

export default SectionProductos;

/* <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <img
                      alt="..."
                      src={require("../../assets/img/patatas-landing-pg.jpg")}
                    />
                  </div>
                  <CardBody>
                    <div className="author">
                      <CardTitle tag="h4">Patatas</CardTitle>
                    </div>
                    <p className="card-description text-center">
                      ¡Nuestro producto estrella! Conoce la mejor calidad de
                      patata del territorio.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-gold"
                      color="link"
                      onClick={event =>
                        (window.location.href = "/productos/patatas")
                      }
                    >
                      Patatas
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <img
                      alt="..."
                      src={require("../../assets/img/cebolla-landing-pg.jpg")}
                    />
                  </div>
                  <CardBody>
                    <div className="author">
                      <CardTitle tag="h4">Cebollas y Ajos</CardTitle>
                    </div>
                    <p className="card-description text-center">
                      Esenciales en nuestros platos. Descubre nuestra grama de
                      cebollas y ajos.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-gold"
                      color="link"
                      onClick={event =>
                        (window.location.href = "/productos/cebollas-y-ajos")
                      }
                    >
                      Cebollas y Ajos
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <img
                      alt="..."
                      src={require("../../assets/img/patatas-landing-pg.jpg")}
                    />
                  </div>
                  <CardBody>
                    <div className="author">
                      <CardTitle tag="h4">Productos Exóticos</CardTitle>
                    </div>
                    <p className="card-description text-center">
                      ¿Te atreves con sabores diferentes? Desde XXX disponemos
                      de una línea de productos orientales. ¡Conócelos!
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-gold"
                      color="link"
                      onClick={event =>
                        (window.location.href = "/productos/productos-exoticos")
                      }
                    >
                      Productos Exóticos
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row> */

//             {/* <div className="productos-lp">
//   <div id="trigger">
//     <Controller>
//       <Scene
//         triggerElement="#trigger"
//         triggerHook="0.7"
//         duration={800}
//         pin={false}
//         enabled
//       >
//         {/* {progress => (
//           <div>
//             <Tween
//               to={{
//                 left: "0px",
//                 rotation: -360,
//                 width: "200px",
//                 height: "200px"
//               }}
//               ease="Strong.easeOut"
//               totalProgress={progress}
//               paused
//             >
//               <h2>Image test 1</h2>
//             </Tween>
//           </div>
//         )} */}
//         <Timeline
//           target={
//             <div>
//               Target element which will be visible and gets tweened
//             </div>
//           }
//         >
//           <Tween
//             from={{
//               opacity: 0
//             }}
//             to={{ opacity: 1 }}
//             ease="Strong.easeOut"
//           />
//           <Tween from={{ x: "-1000px" }} to={{ x: "500px" }} />
//         </Timeline>
//       </Scene>
//     </Controller>
//   </div>
// </div> */}
