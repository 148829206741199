/*

=========================================================
* Elaborats Prepirineu Webpage - v0.0.1
=========================================================

Copyright (C) Elaborats Prepirineu, S.L. - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
Written by Francesc Reig <reig.francesc@gmail.com>, November 2019

=========================================================

*/

import React from "react";

// import GSAP animation effects
import { Power3, TimelineLite } from "gsap";

// import reactstrap components
import { Container, Row, Col } from "reactstrap";

// import Google Maps component for React
import GoogleMapReact from "google-map-react";
// import Google Maps API
const APIGoogleMaps = "AIzaSyCgLYqtGKZeGcGJm4w-pedA9bozN2FlINM";

const Marker = props => {
  const { color, name } = props;
  return (
    <div>
      <div
        className="pin bounce"
        style={{ backgroundColor: color, cursor: "pointer" }}
        title={name}
      />
      <div className="pulse" />
    </div>
  );
};

class SectionOliana extends React.Component {
  constructor(props) {
    super(props);
    this.myTweens = new TimelineLite({ paused: true });
    this.places = [];
  }

  static defaultProps = {
    centerOliana: {
      lat: 42.070017,
      lng: 1.308393
    },
    centerMercabarna: {
      lat: 41.331965,
      lng: 2.117768
    },
    zoomOliana: 11,
    zoomMercabarna: 15
  };

  componentDidMount() {
    this.myTweens
      .staggerFrom(
        this.places,
        2,
        { opacity: 0, y: 40, ease: Power3.easeOut },
        0.5
      )
      .play();
  }

  render() {
    return (
      <div className="section section-dark text-center">
        <Container>
          <Row>
            <h2 className="title text-center ml-auto mr-auto">
              ¿Dónde puedes encontrarnos?
            </h2>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div
                style={{ height: "100%", width: "100%" }}
                ref={div => (this.places[0] = div)}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{ key: APIGoogleMaps }}
                  defaultCenter={this.props.centerOliana}
                  defaultZoom={this.props.zoomOliana}
                >
                  <Marker
                    lat={42.070017}
                    lng={1.308393}
                    color="yellow"
                    text="My Marker"
                  />
                </GoogleMapReact>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="6">
              <div ref={div => (this.places[1] = div)}>
                <h3 className="title" style={{ textAlign: "justify" }}>
                  Oliana
                </h3>
                <h4 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-box-2 mr-3" />
                  Almacén central
                </h4>
                <br />
                <h5 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-pin-3 mr-3" />
                  Polígono Figolers, nº3
                </h5>
                <h5 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-mobile mr-3" />
                  Tel. y Fax: +34 973 470 248
                </h5>
                <h5 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-email-85 mr-3" />
                  oliana@trumfosdelpirineu.com
                </h5>
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div
                style={{ height: "100%", width: "100%" }}
                ref={div => (this.places[2] = div)}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{ key: APIGoogleMaps }}
                  defaultCenter={this.props.centerMercabarna}
                  defaultZoom={this.props.zoomMercabarna}
                >
                  <Marker
                    lat={41.331965}
                    lng={2.117768}
                    color="yellow"
                    text="My Marker"
                  />
                </GoogleMapReact>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="6">
              <div ref={div => (this.places[3] = div)}>
                <h3 className="title" style={{ textAlign: "justify" }}>
                  Mercabarna
                </h3>
                <h4 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-shop mr-3" />
                  Pabellón E
                </h4>
                <br />
                <h5 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-pin-3 mr-3" />
                  Puestos 5017, 5018, 5019
                </h5>
                <h5 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-mobile mr-3" />
                  Tel.: +34 93 556 26 31
                </h5>
                <h5 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-box mr-3" />
                  Fax: +34 93 335 37 41
                </h5>
                <h5 className="description" style={{ textAlign: "justify" }}>
                  <i className="nc-icon nc-email-85 mr-3" />
                  mercabarna@trumfosdelpirineu.com
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SectionOliana;
